import {
  botmartStripePromise,
  copsupplyStripePromise,
  tidalStripePromise,
} from './stripe';

const WHITE_LABEL_BRANDS = {
  undefined: 'Tidal',
  null: 'Tidal',
  botmart: 'BotMart',
  copsupply: 'CopSupply',
};

const WHITE_LABEL_MARKETS = {
  undefined: 'Tidal Market',
  null: 'Tidal Market',
  botmart: 'BotMart',
  copsupply: 'CopSupply',
};

const WHITE_LABEL_URLS = {
  undefined: 'https://www.tidalmarket.com',
  null: 'https://www.tidalmarket.com',
  botmart: 'https://rentals.botmrt.com',
  copsupply: 'https://rentals.cop.supply/',
};

const WHITE_LABEL_TWITTERS = {
  undefined: '@tidalmrkt',
  null: '@tidalmrkt',
  botmart: '@botmrt',
  copsupply: '@cop_supply',
};

const WHITE_LABEL_COMPANIES = {
  undefined: 'Tidal Market Inc',
  null: 'Tidal Market Inc',
  botmart: 'Bot Mart LLC',
  copsupply: 'Cop Supply LLC',
};

const WHITE_LABEL_LOGOS = {
  undefined: '/images/tidal.svg',
  null: '/images/tidal.svg',
  botmart:
    'https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/botmart.svg',
  copsupply:
    'https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/copsupply/COPSUPPLY%20LOGO%20FINAL_TRANSPARENT%20COPSUPPLY%20LANDSCAPE-55%20%282%29.png',
};

const WHITE_LABEL_LANDING_PAGE_SCROLL_ICONS = {
  undefined: 'images/scroll.svg',
  null: 'images/scroll.svg',
  botmart:
    'https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/botmart/home-page/ScrollCursor.svg',
  copsupply:
    'https://tidal-rental.nyc3.cdn.digitaloceanspaces.com/copsupply/Group%207.svg',
};

export const WHITE_LABEL_ACCENT_COLORS = {
  botmart: '#FF003E',
  undefined: '#14b1da',
  null: '#14b1da',
  copsupply: '#3855FF',
};

export const WHITE_LABEL_LIGHT_ACCENT_COLORS = {
  botmart: '#FF003E',
  undefined: '#2db7f5',
  null: '#2db7f5',
  copsupply: '#3855FF',
};

const WHITE_LABEL_FAV_ICONS = {
  undefined: '/images/icon.svg',
  null: '/images/icon.svg',
  botmart: '/images/fav.png',
  copsupply: '/images/cropped-favicon.png',
};

const WHITE_LABEL_MANIFEST_FILES = {
  undefined: '/manifest.json',
  null: '/manifest.json',
  botmart: '/manifest-botmart.json',
  copsupply: '/manifest-copsupply.json',
};

const WHITE_LABEL_TOUCH_ICONS = {
  undefined: '/icon/tidal-logo.png',
  null: '/icon/tidal-logo.png',
  botmart: '/icon/tidal-logo.png',
  copsupply: '/icon/tidal-logo.png',
};

const WHITE_LABEL_GOOGLE_TAG_MANAGERS = {
  undefined: 'GTM-M3S2VR2',
  null: 'GTM-M3S2VR2',
  botmart: 'GTM-W4CTFJZ',
  copsupply: 'GTM-PMXQC68',
};

const WHITE_LABEL_GUILD_IDS = {
  undefined: '500010617113935883',
  null: '500010617113935883',
  copsupply: '768207728359243817',
  botsupply: '430087124876918798',
};

const WHITE_LABEL_GUILD_BANNERS = {
  undefined: '/images/new/discord_widget_header.png',
  null: '/images/new/discord_widget_header.png',
  copsupply: '/images/new/cop_supply_banner.png',
  botmart: '/images/new/bm_banner.png',
};

const WHITE_LABEL_DISCORD_INVITES = {
  undefined: 'https://discord.com/invite/tidal',
  null: 'https://discord.com/invite/tidal',
  botmart: 'https://discord.com/invite/botmart',
  copsupply: 'https://discord.com/invite/X5erWT8wTj',
};

export const WHITE_LABEL_STRIPE_PROMISES = {
  undefined: tidalStripePromise,
  null: tidalStripePromise,
  botmart: botmartStripePromise,
  copsupply: copsupplyStripePromise,
};

const whiteLabelOrg = process.env.WHITE_LABEL_ORG || null;

export const WHITE_LABEL_GUILD_ID = (() =>
  WHITE_LABEL_GUILD_IDS[whiteLabelOrg])();

export const WHITE_LABEL_DISCORD_INVITE = (() =>
  WHITE_LABEL_DISCORD_INVITES[whiteLabelOrg])();

export const WHITE_LABEL_GUILD_BANNER = (() =>
  WHITE_LABEL_GUILD_BANNERS[whiteLabelOrg])();

export const WHITE_LABEL_GOOGLE_TAG_MANAGER = (() =>
  WHITE_LABEL_GOOGLE_TAG_MANAGERS[whiteLabelOrg])();

export const WHITE_LABEL_MANIFEST_FILE = (() =>
  WHITE_LABEL_MANIFEST_FILES[whiteLabelOrg])();

export const WHITE_LABEL_TOUCH_ICON = (() =>
  WHITE_LABEL_TOUCH_ICONS[whiteLabelOrg])();

export const WHITE_LABEL_FAV_ICON = (() =>
  WHITE_LABEL_FAV_ICONS[whiteLabelOrg])();

export const WHITE_LABEL_ACCENT_COLOR = (() =>
  WHITE_LABEL_ACCENT_COLORS[whiteLabelOrg])();

export const WHITE_LABEL_LIGHT_ACCENT_COLOR = (() =>
  WHITE_LABEL_LIGHT_ACCENT_COLORS[whiteLabelOrg])();

export const WHITE_LABEL_BRAND = (() => WHITE_LABEL_BRANDS[whiteLabelOrg])();

export const WHITE_LABEL_MARKET = (() => WHITE_LABEL_MARKETS[whiteLabelOrg])();

export const WHITE_LABEL_URL = (() => WHITE_LABEL_URLS[whiteLabelOrg])();

export const WHITE_LABEL_TWITTER = (() =>
  WHITE_LABEL_TWITTERS[whiteLabelOrg])();

export const WHITE_LABEL_COMPANY = (() =>
  WHITE_LABEL_COMPANIES[whiteLabelOrg])();

export const WHITE_LABEL_LOGO = (() => WHITE_LABEL_LOGOS[whiteLabelOrg])();

export const WHITE_LABEL_LANDING_PAGE_SCROLL_ICON = (() =>
  WHITE_LABEL_LANDING_PAGE_SCROLL_ICONS[whiteLabelOrg])();

export const WHITE_LABEL_STRIPE_PROMISE = (() =>
  WHITE_LABEL_STRIPE_PROMISES[whiteLabelOrg])();

export const TIDAL_URL = (() => WHITE_LABEL_URLS['undefined'])(); // so aids but works

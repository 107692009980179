import { ApolloProvider, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { view } from '@risingstack/react-easy-state';
import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress
import Router, { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Slide, ToastContainer } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum-slim';
import 'react-toastify/dist/ReactToastify.min.css';
import 'tailwindcss/tailwind.css';
import Footer from '../components/footer';
import { COOKIE } from '../constants';
import { useApollo } from '../lib/apollo';
import mobileMenu from '../stores/mobileMenu';
import { lightThemeWeb } from '../theme/web';
import styled, { ThemeProvider } from 'styled-components';
import TidalNavbar from '../components/navbar';
import { Box } from '../components/Flex';

// tidal dashboard
import { SWRConfig } from 'swr';
import { fetcher } from '../api/bootstrap';

// intercom
import { handleGoogleLoginData, initIntercom } from '../lib/utils';
import { ME } from '../lib/graphql/queries';
import { trackEvent } from 'next-intercom';
import { DefaultSeo } from 'next-seo';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { SAVE_NOTIF_TOKEN, VERIFY_GOOGLE_ID } from '../lib/graphql/mutations';
import Head from 'next/head';
import { WHITE_LABEL_BRAND, WHITE_LABEL_MARKET } from '../lib/white-label';
import { firebaseCloudMessaging } from '../lib/web-push';
import localforage from 'localforage';
import Device from '../components/device';
import AppModal from '../components/appModal';
import { analytics } from '../lib/analytics';

//Binding events.
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.extend(timezone);
dayjs.extend(RelativeTime);

const settingsRegex = new RegExp(
  /\/tidal-dashboard\/([^\/]+?)(?:\/)?\/settings/,
);

const MainWrapper = styled.div<{
  isNavbarVisible: boolean;
}>`
  display: grid;
  background-color: ${(props) => props.theme.colors.background};
  grid-template-columns: ${(props) =>
    props.isNavbarVisible ? '264px 1fr' : '1fr'};
  max-width: 100%;
  column-gap: 10px;

  @media only screen and (max-width: 800px) {
    grid-template-columns: ${(props) =>
      props.isNavbarVisible ? '100px 1fr' : '1fr'};
  }
  @media only screen and (max-width: 540px) {
    grid-template-columns: ${(props) =>
      props.isNavbarVisible ? '80px 1fr' : '1fr'};
  }

  @media only screen and (max-width: 320px) {
    grid-template-columns: ${(props) =>
      props.isNavbarVisible ? '40px 1fr' : '1fr'};
  }
`;

const WebsiteViewWrapper = styled.div<{ hasMaxWidth: boolean }>`
  max-width: ${(props) => (props.hasMaxWidth ? '1600px' : '1600px')};
  margin: 0 auto;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
`;

const WebsiteView = styled.div<{ isHomePage: boolean; padding: number }>`
  display: grid;
  position: relative;
  max-width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 ${(props) => (props.isHomePage ? '1rem' : `${props.padding}rem`)};
  @media only screen and (max-width: 800px) {
    padding: 0 ${(props) => (props.isHomePage ? '1rem' : `${props.padding}rem`)};
  }
  @media only screen and (max-width: 540px) {
    padding: 0
      ${(props) => (props.isHomePage ? '0.4rem' : `${props.padding}rem`)};
  }
`;

if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  datadogRum.init({
    applicationId: '67befea8-13f2-404d-a45e-1372ae3f67d4',
    clientToken: 'pub4e6b23729a1901c7dba39b8ac0ed8b64',
    site: 'us5.datadoghq.com',
    service: WHITE_LABEL_BRAND || 'web-app',
    env: process.env.NEXT_PUBLIC_VERCEL_ENV || 'default',
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const App = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [padding, setPadding] = useState(1);
  const [location, setLocation] = useState('/');
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const desktopSeparation = 20;
  const desktopSeparationWithBack = 24;
  const [isHomePage, setIsHomePage] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE.ACCESS_TOKEN]);
  const hide = () => {
    if (mobileMenu.toggled) {
      mobileMenu.setToggled(false);
    }
  };

  const router = useRouter();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(`/firebase-messaging-sw.js?messagingSenderId=739737537788`)
        .then(
          function (registration) {
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope,
            );
          },
          function (err) {
            console.log('Service Worker registration failed: ', err);
          },
        );
    }

    firebaseCloudMessaging.init().then(() => {
      return localforage.getItem('fcm_token').then((token) => {
        if (token && !saveTokenCalled) {
          saveNotificationToken({
            variables: {
              token,
              source: 'web',
            },
          })
            .then(() => {
              console.log('saved token');
            })
            .catch((err) => console.error(err));
        }
      });
    });
  }, []);

  useEffect(() => {
    if (router.isReady) {
      const intercom = router.query['intercom'];
      const app = router.query['app'];
      const isIntercomOpen = intercom == 'true';

      if (app) {
        // disable intercom when loaded in app
        return;
      }

      if (!cookies[COOKIE.ACCESS_TOKEN]) {
        initIntercom(isIntercomOpen);
      } else {
        getMe().then((val) => {
          if (val.error) {
            console.error(val.error);
            trackEvent('failed-init', { error: val.error });
            initIntercom(isIntercomOpen);
          } else {
            initIntercom(
              isIntercomOpen,
              val.data.me.email,
              val.data.me.intercomHash,
              `${val.data.me.firstName} ${val.data.me.lastName || ''}`,
              val.data.me.id,
            );
            analytics.identify(val.data.me.id, {
              email: val.data.me.email,
              name: `${val.data.me.firstName} ${val.data.me.lastName || ''}`,
            });
          }
        });
      }
    }
  }, [router.isReady, cookies]);

  useEffect(() => {
    setLoggedIn(!!cookies[COOKIE.ACCESS_TOKEN]);
    return () => {
      document.getElementById('bounds').removeEventListener('click', hide);
    };
  }, [mobileMenu.toggled, cookies]);

  useEffect(() => {
    setLocation(window.location.pathname);
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/landing'
    ) {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
    if (
      window.location.pathname == '/login' ||
      window.location.pathname == '/register' ||
      window.location.pathname == '/login/forgot'
    )
      setPadding(0);
    else setPadding(1);
  }, [router.pathname]);

  useEffect(() => {
    if (router.asPath === '/maintenance' || router.asPath.includes('?app=true'))
      setIsNavbarVisible(false);
    else setIsNavbarVisible(true);
  }, [router.asPath]);

  const onLoggedOut = () => {
    setLoggedIn(false);
    removeCookie(COOKIE.ACCESS_TOKEN, { path: '/' });
    removeCookie(COOKIE.REFRESH_TOKEN, { path: '/' });
    apolloClient.resetStore();
    window.location.assign('/login');
    window.google.accounts.id.disableAutoSelect();
  };

  if (typeof document !== 'undefined' && document.getElementById('bounds')) {
    document.getElementById('bounds').addEventListener('click', hide);
  }

  // const Content = withSpacing(Component);

  if (!apolloClient) return null;

  const [getMe, { data: getMeData, error: getMeError }] = useLazyQuery(ME, {
    client: apolloClient,
  });

  if (!apolloClient) return;

  const [verifyGoogle, { loading, error, called }] = useMutation(
    VERIFY_GOOGLE_ID,
    { client: apolloClient },
  );

  const [
    saveNotificationToken,
    {
      loading: saveTokenLoading,
      error: saveTokenError,
      called: saveTokenCalled,
    },
  ] = useMutation(SAVE_NOTIF_TOKEN, { client: apolloClient });

  useEffect(() => {
    const interval = setInterval(() => {
      if (window?.google) {
        try {
          window.google.accounts.id.initialize({
            client_id: process.env.GOOGLE_CLIENT_ID,
            context: router.pathname.includes('register') ? 'signup' : 'signin',
            ux_mode: 'popup',
            auto_select: true,
            itp_support: true,
            callback: async (response) => {
              const { data } = await verifyGoogle({
                variables: {
                  googleData: { token: response.credential },
                },
              });
              const loginData = data.verifyGoogleId;
              analytics.track(`User Logged In With Google`);
              handleGoogleLoginData(loginData, setCookie, router);
            },
          });
          if (!cookies[COOKIE.ACCESS_TOKEN]) {
            window.google.accounts.id.prompt();
          }
          clearInterval(interval);
        } catch (error) {
          console.error(error);
        }
      }
    }, 100);
  }, [router.asPath, cookies]);

  return (
    <SWRConfig
      value={{
        fetcher,
        suspense: false,
        focusThrottleInterval: 60000,
      }}
    >
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
      </Head>
      <ApolloProvider client={apolloClient}>
        <DefaultSeo
          title={WHITE_LABEL_MARKET}
          description="Use your favorite bots without having to buy."
        />
        {/* <MediaContextProvider disableDynamicMediaQueries> */}
        <ToastContainer
          className="impct-toast"
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          draggable={false}
          pauseOnHover
          transition={Slide}
          style={{ maxWidth: 500, width: '95%' }}
        />
        {/* <Header isLoggedIn={isLoggedIn} onLoggedOut={onLoggedOut} /> */}
        <Device>
          {({ isMobile, isAndroid, isIOS }) => {
            return (
              <AppModal
                show={isMobile && WHITE_LABEL_BRAND === 'Tidal'}
                isAndroid={isAndroid}
                isIOS={isIOS}
              />
            );
          }}
        </Device>
        <div id="bounds">
          {/* @ts-ignore */}
          <ThemeProvider theme={lightThemeWeb}>
            <MainWrapper isNavbarVisible={isNavbarVisible}>
              {isNavbarVisible && (
                <div>
                  <TidalNavbar
                    onLoggedOut={onLoggedOut}
                    isLoggedIn={isLoggedIn}
                    meData={getMeData?.me || null}
                  />
                </div>
              )}
              <WebsiteViewWrapper
                hasMaxWidth={
                  location != '/login' &&
                  location != '/register' &&
                  location != '/login/forgot'
                }
              >
                <WebsiteView
                  padding={padding}
                  isHomePage={isHomePage}
                  id="websiteView"
                >
                  {location != '/login' &&
                    location != '/register' &&
                    location != '/login/forgot' && (
                      // !location?.includes('/blog/') &&
                      // !location?.includes('/shop/') &&
                      // !location?.includes('/dashboard') &&
                      <Box
                        height={
                          !settingsRegex.test(location)
                            ? desktopSeparation
                            : desktopSeparationWithBack
                        }
                      />
                    )}
                  <Component {...pageProps} />
                  <Box height={desktopSeparation} />
                  <Footer />
                </WebsiteView>
              </WebsiteViewWrapper>
            </MainWrapper>
          </ThemeProvider>
        </div>

        {/* </MediaContextProvider> */}
      </ApolloProvider>
    </SWRConfig>
  );
};

export default view(App);
